import jenniferHo from "src/assets/images/OurFaculty/JenniferHo.png";
import annaBekkerImg from "src/assets/images/OurFaculty/annaBekker.png";
import manuImg from "src/assets/images/OurFaculty/manu.png";

import johnnyBaylessImg from "src/assets/images/OurFaculty/johnnyBayless.png";
import keithImg from "src/assets/images/OurFaculty/keith.png";
import jiayanImg from "src/assets/images/OurFaculty/jiayan.JPG";
import lenoraImg from "src/assets/images/OurFaculty/lenora.jpg";
import sharynMooreAdmin from "src/assets/images/OurFaculty/sharynMooreAdmin.jpg";
import ahmedElkamshoushyAdmin from "src/assets/images/OurFaculty/ahmedElkamshoushyAdmin.jpg";
import dickMathias from "src/assets/images/OurFaculty/dickMathias.jpg";
import audreyImg from "src/assets/images/OurFaculty/audrey.jpeg";
import CharlieThollander from "src/assets/images/OurFaculty/CharlieThollander.JPG";
import NazarPankiv from "src/assets/images/OurFaculty/NazarPankiv.PNG";
import RachelL from "src/assets/images/OurFaculty/RachelL.jpg";
import ChandaTongo from "src/assets/images/OurFaculty/ChandaTongo.jpeg";
import IreneLong from "src/assets/images/OurFaculty/IreneLong.jpg";
import drLubo from "src/assets/images/OurFaculty/DrLubo.png";
import MingLam from "src/assets/images/OurFaculty/MingLam.jpeg";
import JenniferGentry from "src/assets/images/OurFaculty/JenniferGentry.jpeg";
import IsabelDeCastro from "src/assets/images/OurFaculty/IsabelDeCastro.png";

import { GiViolin } from "react-icons/gi";
import { GiGrandPiano } from "react-icons/gi";
import { MdKeyboardVoice } from "react-icons/md";
import { BsPersonWorkspace } from "react-icons/bs";
import { GiMeditation } from "react-icons/gi";
import { LiaGuitarSolid } from "react-icons/lia";
import { GiDrum } from "react-icons/gi";
import { GiFlute } from "react-icons/gi";
import { GiSaxophone } from "react-icons/gi";
import { FaLinkedin } from "react-icons/fa6";
import { SiCrunchbase } from "react-icons/si";
import { GiGuitar } from "react-icons/gi";
import { GiClarinet } from "react-icons/gi";

const teachers = [
  {
    image: jenniferHo,
    name: "Jennifer Ho",
    profession: (
      <>
        Artis School of Music Director,
        <br />
        Teacher for Piano Teachers,
        <br />
        Teacher for Violin Teachers,
        <br />
        Meditation Instructor
      </>
    ),
    recordingLink: "#",
    specialization: [
      {
        Icon: BsPersonWorkspace,
        amount: 9,
        name: "Artis school Director",
        notListedOnPI: true,
      },
      { Icon: GiGrandPiano, amount: 9, name: "Piano" },
      { Icon: GiViolin, amount: 9, name: "Violin" },
      { Icon: GiMeditation, amount: 5, name: "Meditation" },
    ],
    social: [
      {
        Icon: FaLinkedin,
        name: "LinkedIn profile",
        url: "https://www.linkedin.com/in/jennifer-ho-045aa981/",
      },
      {
        Icon: SiCrunchbase,
        name: "Crunchbase profile",
        url: "https://www.crunchbase.com/person/jennifer-ho",
      },
    ],
    about: (
      <>
        <p>
          Experienced Director with a demonstrated history of working in the
          education management industry. Skilled in Entrepreneurship, Strategy,
          Product Management, Teaching, and Start-ups. Strong professional
          graduated from City University of New York-Queens College.
        </p>
        <p>Arte Piano Quartet Violanist 2006 San Mateo Ca</p>
        <p>
          First Violinist Stockton Symphony 2006 - 2008 Stockton, California
          Area{" "}
        </p>
        <p>
          Founder and Teacher Director Happy Mozart Music School 2007 - 2012 San
          Mateo
        </p>
        <p>
          Jennifer Ho is the Founder Director Artis school of Music since 2015
          Jennifer established a new Belmont location to dedicate her knowledge
          and experience to create and maintain music development in Belmont
          community and beyond. in 2022 Jennifer lunched a new web platform that
          will help make it much more convenient to provide excellent music
          education experience. Jennifer though many students, facilitated
          regularly community concerts that enhancing local cultural development{" "}
        </p>
        <p>
          in 2024 Jennifer is focusing more on teaching school Teachers and
          Improving Web platform with adding more innovative Global expansion
          this coming season! Subscribe to Our E-News to stay updated it.. New
          Prospects and Our dear Past Students Do not wait .. You can contact Us
          Now before teachers schedule will be unavailable for upcoming time…!
          We exited to announce Guest feature that will allow new prospect to
          join Our Team in growing Career by becoming Artis Teacher into
          Certified credentials
        </p>
        <p> Lunching new Join Our partner collaborative opportunities !</p>
        <p>
          {" "}
          And new Artis students can sign up from anywhere where is a good
          internet connection ...
        </p>
        <p>
          We value to provide the best service and technology that will enhance
          customers experience.{" "}
        </p>
        <p>Artis school of music </p>
      </>
    ),
  },
  {
    image: annaBekkerImg,
    name: "Anna Bekker",
    profession: (
      <>
        Artis Belmont Location Manager Assistant,
        <br />
        Pianist And Mezzo-Soprano,
        <br />
        Piano And Vocal Instructor
      </>
    ),
    recordingLink: "#",
    specialization: [
      { Icon: GiGrandPiano, amount: 6, name: "Piano" },
      { Icon: MdKeyboardVoice, amount: 6, name: "Voice" },
      {
        Icon: BsPersonWorkspace,
        amount: 4,
        name: "Artis school Manager assistant",
        notListedOnPI: true,
      },
    ],
    about: (
      <>
        <p>
          Mezzo-soprano <strong>Anna Bekker</strong> began her musical studies
          at the piano at age five. Eleven years later, in 2010, Anna received
          her qualifications as a private instructor and began teaching piano.
          The following year, Anna enrolled at the University of Rochester’s
          Eastman School of Music where she continued studying piano while
          pursuing a Bachelor of Music in vocal performance. Anna later returned
          to Eastman, graduating with a Master of Music in vocal performance and
          literature.
        </p>
        <p>
          Over the years, Anna has had the pleasure of performing as both a
          vocalist and a pianist in various settings throughout the United
          States and abroad, including concert tours in England, Ireland,
          Scotland, Wales, Germany, Poland, the Czech Republic, and Italy. Anna
          has spent a great deal of her musical career as a choral singer,
          performing extensively, including performances in Davies Symphony Hall
          and Carnegie Hall. In addition to her passion for performance, Anna
          feels at home teaching, and she is both honored and excited to be on
          staff with the Artis School of Music.
        </p>
      </>
    ),
  },
  {
    image: johnnyBaylessImg,
    name: "Johnny Bayless",
    profession: (
      <>
        Guitarist,
        <br />
        Guitar Instructor,
        <br />
        Ukulele Instructor
      </>
    ),
    recordingLink: "#",
    specialization: [
      { Icon: GiGuitar, amount: 9, name: "Guitar" },
      { Icon: LiaGuitarSolid, amount: 9, name: "Ukulele" },
    ],
    about: (
      <p>
        <strong>Johnny Bayless</strong> has completed studies in theory, guitar,
        mandolin, ukulele, bass and piano. He is certified in Protools and
        experienced as a studio musician. Johnny recently experienced teaching
        with a colleague who was his student for many years. Johnny inspired
        another student to apply and get accepted by the Juilliard School of
        Music. Johnny spent years with Motown records in an act called Misa
        which appeared on shows such as Yo MTV Raps and Arsenio Hall. He also
        worked in various bands through the years like City Jade, Internal
        Affairs and Soul Out, to name a few. Now in his spare time, Johnny plays
        in his band, Black Mast.
      </p>
    ),
  },
  {
    image: keithImg,
    name: "Keith Fongheiser",
    profession: (
      <>
        Drumer,
        <br />
        Drum Instructor
      </>
    ),
    recordingLink: "#",
    specialization: [{ Icon: GiDrum, amount: 7, name: "Drum" }],
    about: (
      <>
        <p>
          <strong>Keith’s</strong> goal is to teach music in a way that
          invigorates a sense of curiosity not only in the euphoric physics of
          sound, but in the practical uses of controlling and organizing sound
          in an effort to better understand and explain one’s self as well as
          one’s surroundings.
        </p>

        <p>
          Keith believes that his role as a teacher is to promote focus,
          introspection, and mindfulness through intent and efficient practice.
          He has developed his own structure of practice that has allowed him to
          discuss and explore the various de- tails of music with students of
          all ages and unique intellectual strengths. Whether his students are
          career-oriented or just looking for a healthy, fun, and challenging
          hobby, he aims to build a unique road of discovery for each and every
          one. He also emphasizes the importance of group classes so that every
          musician has the chance to experience the universal conversation that
          is music.
        </p>
        <p>
          Having played drum set and percussion (including piano) since the age
          of four, Keith started his own band in 7th grade and began playing
          shows by 8th grade. He has played in various styles of groups from
          rock, blues, and jazz to fusion and reggae.
        </p>
        <p>
          {" "}
          Keith studied composition, music history and business, engineering and
          recording arts, as well as scoring for visual media at Diablo Valley’s
          renowned music department. In 2011, Keith studied composition,
          classical percussion, piano, guitar and bass at Notre Dame De Namur
          here in Belmont for two years.
        </p>
        <p>
          Keith has been teaching group classes at local private schools K-12 as
          well as private lessons here at Artis School of Music for the last few
          years. He has more than 10 years of experience teaching private drum
          set and percussion lessons between the Bay Area and Long Beach. He
          also has multiple years’ experience teaching beginner to intermediate
          guitar, bass, and piano.
        </p>
      </>
    ),
  },
  {
    image: manuImg,
    name: "Manu Petaia",
    profession: (
      <>
        Pianist,
        <br />
        Piano Instructor
      </>
    ),
    recordingLink: "#",
    specialization: [{ Icon: GiGrandPiano, amount: 4, name: "Piano" }],
    about: (
      <>
        <p>
          <strong>Manu Petaia</strong> completed his Bachelor’s Degree in Piano
          Performance at San Francisco State University in 2016 under the
          tutelage of Dr. R. Woodward, and his Masters degree in the fall of
          2021 under Dmitry Rachmanov. He began playing piano at the age of 6,
          and throughout his childhood and into early adulthood, was exposed to
          a great body of choral work through his Dad, who received his
          Bachelors from San Francisco State University before him.{" "}
        </p>
        <p>
          He is very, very fond of the music of Bach and Mozart, and by
          extension, much of the music—both for piano and other instrumental
          ensembles—of the 17th and 18th Centuries. If given the opportunity, he
          would very much like to engage in a thorough study of the early Mozart
          Piano Sonatas. Recently, he has taken a great interest in learning
          jazz as a vehicle by which to explore improvisation in a western
          classical music setting.
        </p>
      </>
    ),
  },
  {
    image: audreyImg,
    name: "Audrey Fish",
    profession: "Voice Instructor",
    recordingLink: "#",
    specialization: [{ Icon: MdKeyboardVoice, amount: 2, name: "Voice" }],
    about: (
      <>
        <p>
          <strong>Audrey</strong>’s music journey began at the age of 5 when she
          joined her first choir and took up the violin. She continued these all
          throughout her childhood, joining several national and state-wide
          ensembles along the way, including CCDA’s honor choirs, NAFME’s
          national honor ensemble, the Peninsula Youth Orchestra, and Peninsula
          Girls’ Chorus. She began teaching voice, violin, and music theory in
          high school, jobs which fostered a lifelong passion for working with
          students.{" "}
        </p>
        <p>
          {" "}
          She also started to teach herself piano and guitar, instruments she
          continues to learn today. Audrey graduated magna cum laude from
          Chapman University with BA degrees in psychology and general music
          (with a vocal emphasis) where she continued teaching and started
          arranging choral and a cappella music. Though her favorite type of
          music to perform is pop, she has experience both singing and teaching
          classical, musical theater, and choral styles.{" "}
        </p>
        <p>
          Audrey’s teaching philosophy centers around the tenets of engagement
          and flexibility. She believes that her most important job as a teacher
          is to encourage her students’ enthusiasm for music by displaying her
          own. She believes the best way to accomplish this is to meet the
          student where they are in terms of ability and learning style. She
          hopes that through lessons at Artis she and her students can continue
          to inspire one another to be better musicians.{" "}
        </p>
        <p>
          When she’s not practicing, teaching, or performing, Audrey can be
          found assisting with psychology research at Stanford University,
          playing with her dog, or reading.
        </p>
      </>
    ),
  },
  {
    image: jiayanImg,
    name: "Jiayan Zhang",
    profession: (
      <>
        Pianist,
        <br />
        Piano Instructor
      </>
    ),
    recordingLink: "#",
    specialization: [{ Icon: GiGrandPiano, amount: 2, name: "Piano" }],
    about: (
      <>
        <p>
          <strong>Jiayan Zhang</strong> has been playing piano since she went to
          elementary school. She completed her bachelor’s degree in Music with
          an emphasis in piano performance at California State University East
          Bay (CSUEB) in June 2016.{" "}
        </p>
        <p>
          {" "}
          During her time at CSUEB, she classically trained on the piano. In
          addition, she also took lessons in learning musical disciplines and
          pedagogical approaches at school. She teaches piano lessons privately
          to students of all ages and levels, prepares students for exams, and
          plays piano accompaniment in students’ instrumentals recitals and
          exams.{" "}
        </p>
        <p>
          {" "}
          Jiayan hopes students feel joyful while learning music in her lessons.
          Therefore, she designs lessons to meet every student’s needs.
          Moreover, Jiayan believes that through learning an instrument,
          students not only gain the knowledge of playing an instrument but also
          become people with self-discipline and perseverance.
        </p>
      </>
    ),
  },
  {
    image: lenoraImg,
    name: "Lenora Mathias",
    profession: "Flute Instructor",
    recordingLink: "#",
    specialization: [{ Icon: GiFlute, amount: 2, name: "Flute" }],
    about: (
      <>
        <p>
          <strong>Lenora Mathias</strong> has appeared as flute soloist in
          concert with the Rimsky- Korsakov String Quartet, Sounds New,
          Pennisula Women’s Chorus, Composers’ Inc., San Francisco Camerata
          Americana, and Orquesta Sinfonica Nacional del Peru.{" "}
        </p>
        <p>
          She has performed with many bay area ensembles, including West Edge
          Opera, and the Napa Valley, Oakland East Bay, Monterey, Santa Cruz,
          Santa Rosa and Fremont Symphonies. Lenora holds a Master of Fine Arts
          degree from the University of California at Los Angeles where she was
          teaching assistant for the Wind Ensemble and music history classes.{" "}
        </p>
        <p>
          Awards include first prizes in the Frank Sinatra Competition
          (Classical Instrumentalist), the Atwater Kent, and the UCLA Concerto
          Competition. From 2010 to 2013, she served as Music Director of the
          Junior Bach Festival. A frequent adjudicator for local music festivals
          and competitions, Lenora enjoys teaching private flute and piano
          lessons at her home in Oakland.{" "}
        </p>
        <p>
          {" "}
          Her students have performed in the Junior Bach Festival, San Francisco
          Youth Orchestra, Young Peoples Symphony Orchestra, Berkeley Youth
          Orchestra, local theater orchestras, Santa Cruz Jazz Festival, and
          California Jazz Conservatory Ensembles.
        </p>
      </>
    ),
  },
  {
    image: dickMathias,
    name: "Dick Mathias",
    profession: (
      <>
        Clarinet,
        <br />
        Saxophone Instructor,
        <br />
        Flute Instructor
      </>
    ),
    recordingLink: "#",
    specialization: [
      { Icon: GiClarinet, amount: 1, name: "Clarinet" },
      { Icon: GiSaxophone, amount: 1, name: "Saxophone" },
      { Icon: GiFlute, amount: 1, name: "Flute" },
    ],
    about: (
      <>
        <p>
          {" "}
          Hi, I’m <strong>Dick</strong> and I’m a “woodwind doubler.” That means
          I play various sizes and shapes of clarinets, saxophones, flutes, and
          occasionally recorders. If you look down into the orchestra pit for a
          Broadway musical theatre production, you’ll see a few of us down there
          with a large assortment of instruments. This is where I’ve spent a
          good portion of my musical life—playing shows such as West Side Story,
          A Chorus Line, Beauty and the Beast, and many more. Recently I’ve been
          performing with several Bay Area jazz big bands (The Morchestra, Don
          Piestrup Band, Royal Society Jazz Orchestra), classical orchestras
          (Bay Philharmonic, West Edge Opera), and a unique jazz/classical
          chamber group called the Albatross Clarinet Quartet.{" "}
        </p>
        <p>
          {" "}
          I began my musical training in East Bay public schools where my
          teachers, all working musicians, instilled a sense of artistry, craft,
          and professionalism. At Chabot College I started composing for the
          bands and readers’ theatre productions. Continuing on the San
          Francisco Conservatory of Music, I had several exceptional teachers
          who helped me further develop my interest in composing and arranging.{" "}
        </p>
        <p>
          Many young students, as I was, are attracted to instruments like the
          clarinet or saxophone because “I want to play in the band!” It is the
          foundation of my teaching to help students, as my teachers did with
          me, develop their fundamental instrumental and musicianship skills in
          ways that open doors to any type of musical experience they choose.{" "}
        </p>
        <p>
          I am now completing my 19th year as a music teacher at the Branson
          School in Ross. Prior to that I taught for several years at the Nueva
          School in Hillsborough.
        </p>
      </>
    ),
  },
  {
    image: IreneLong,
    name: "Irene Long",
    profession: "Piano, Piano Instructor",
    recordingLink: "#",
    specialization: [{ Icon: GiGrandPiano, amount: 1, name: "Piano" }],
    about: (
      <>
        <p>
          Pianist & Accompanist <strong>Irene Yin Long</strong>, award-winning
          pianist and accompanist, has performed internationally from Washington
          D.C. to Singapore. Winner of the Hong Kong International Piano
          Competition, she was awarded the Distinction in New Music by National
          Artistry Honors in 2022, leading to a tour of contemporary music
          composition. Long received the Master of Piano Performance degree at
          Indiana University Jacobs School of Music.
        </p>
        <p>
          As principal and Orchestra Pianist at National Music Festival Symphony
          Orchestra in Washington D.C., Indiana University Symphony Orchestras
          and Wind Ensemble, New World Symphony Orchestra in Miami, she
          collaborates with esteemed conductors and performs a diverse
          repertoire, including works by Saint-Saëns, Stravinsky, Ligeti, and
          more. Long is also a skilled improvisation specialist, often
          incorporating improvisational elements into her performances.
        </p>
        <p>
          Long is a versatile accompanist in the fields of Theater and Dance,
          having served as a professional accompanist at Jacobs Ballet Theater,
          Boston Belvoir Ballet and Chicago Summer Intensive, she has
          collaborated with faculty members from the New York City Ballet, the
          Royal Ballet in London, and the Boston Ballet. Additionally, Long is
          music director at the Bay View Music Festival, Boston Belvoir Summer
          Institute. Her exceptional talent and versatility have made her a
          sought-after pianist and accompanist across the country.
        </p>
      </>
    ),
  },
  {
    image: CharlieThollander,
    name: "Charlie Thollander",
    profession: "Violin and Viola",
    raisingTalent: false,
    recordingLink: "#",
    specialization: [{ Icon: GiViolin, amount: 1, name: "Violin" }],
    about: (
      <>
        <p>
          <strong>Charlie Thollander</strong> is a San Francisco raised
          violinist who pursued his bachelor's degree in music at UCLA and his
          master's degree at Boston University. He studied with three previous
          teachers who are alumni of the Moscow Conservatory and Saint
          Petersburg Conservatory, influencing Charlie with the Russian style of
          violin playing. Charlie is an active musician with recent experience
          traveling to Germany and Austria for competing in a music competition.
          Charlie's teaching style is one of patience and deliberate learning.
        </p>
        <p>
          Students in Charlie's studio will learn the foundations of
          instrumental technique, music theory, and musicianship under the
          standard practices of conservatory training.
        </p>
      </>
    ),
  },
  {
    image: drLubo,
    name: "Dr. Ljubomir Velickovic",
    profession: "Violin and Viola",
    raisingTalent: false,
    recordingLink: "#",
    specialization: [{ Icon: GiViolin, amount: 1, name: "Violin" }],
    about: (
      <>
        <p>
          <strong>Dr. Ljubomir Velickovic</strong> holds a DMA in performance at
          University of Texas at Austin. Velickovic is a renowned violinist and
          violist with considerable international experience.
        </p>
        <p>
          Dr. Velickovic studied violin in Belgrade, Sophia, and with teachers
          from Moscow, Japan, and the United States. He played in master classes
          for Max Rostal, Henry Roth, Isaac Stern, and Dorothy Delay. He has
          performed as a soloist and in chamber music groups in Yugoslavia,
          Serbia, France, Germany, Italy, Belgium,Ireland, Canada, South Africa
          and Japan. He performed in the Broadway-bound production of La Boheme
          in San Francisco and in functions for Gregory Peck, Ladybird Johnson
          and Walter Cronkite in Austin, Texas. He was violinist for Smokey
          Robinson and Ann Murray in California and was a violist with Mannheim
          Steamroller as well as for Luciano Pavarotti at the Arco Arena in
          Sacramento.
        </p>
        <p>
          Dr. Velickovic participated in recordings at Willie Nelson Studios in
          Austin, Texas and at George Lucas’ Skywalker Sound Studios. He taught
          violin, viola and strings at El Camino College, California State
          University at Stanislaus, San Joaquin Delta College and Sam Houston
          University. Dr.Velickovic performed Bruch Violin Concerto as a soloist
          with El Camino College Orchestra and was the soloist of Strandberg’s
          Viola Concerto at Sam Houston University. He performed and taught at
          the Beverly Hills International Music Festival and was a faculty
          member of Chico Chamber Music Festival.
        </p>
        <p>
          Dr. Velickovic is assistant concertmaster of the Stockton Symphony and
          first violinist of the Sacramento Philharmonic. He is also the founder
          and director of Divertimento Chamber Group and Baroque Chamber
          Orchestra and teaches widely in the Bay area. The Stockton Record
          wrote several articles about him and his performances.
        </p>
      </>
    ),
  },
  {
    image: MingLam,
    name: "Ming Lam",
    profession: "Drum and Recording Arts Instructor",
    raisingTalent: true,
    recordingLink: "#",
    specialization: [{ Icon: GiDrum, amount: 1, name: "Drum" }],
    about: (
      <>
        <p>
          <strong>Ming Lam</strong> is a drummer and recording artist from the
          Bay Area. As a teacher, he has seen many people discouraged by the
          belief that musicians possess natural talent or technical abilities
          beyond their own reach. His mission is to break down this barrier,
          demystifying the process with patience to make music feel accessible
          to all students.
        </p>
        <p>
          Ming Lam has been playing drums since a young age and has live
          performance experience in multiple styles, including rock, jazz, and
          funk. His lessons prioritize technique, mental coordination, as well
          as the “pocket” – teaching students to feel the groove of music in
          both body and mind.
        </p>
        <p>
          In Recording Arts, students learn how to take creative control over
          their existing abilities through digital music production and
          composition. Through the process of creating original songs, students
          gain a broader understanding of how music is arranged in a variety of
          new styles and a working foundation in keyboard, bass, and drums. His
          curriculum adapts the genres and instruments students are passionate
          about to teach fundamental listening skills, music theory, recording
          techniques, and mixing practices.
        </p>
        <p>
          Ming Lam graduated from Pomona College, where he focused his research
          on human cognition of musical timbre. He has also released original
          music through a grant-funded project in which he researched the
          history and diasporic expressions of Asian American music and composed
          an album that reflected his findings.
        </p>
      </>
    ),
  },
  {
    image: JenniferGentry,
    name: "Jennifer Gentry",
    profession: "Piano, Music Theory Instructor",
    raisingTalent: false,
    recordingLink: "#",
    specialization: [{ Icon: GiGrandPiano, amount: 1, name: "Piano" }],
    about: (
      <>
        <p>
          Jennifer Gentry is a passionate and dedicated music educator with
          nearly a decade of experience in piano, vocal, and theory instruction.
          Holding a Bachelor’s Degree in Music Theory and Composition from Santa
          Clara University, she has cultivated a diverse teaching background,
          spanning private and group piano and voice lessons, choral singing,
          and music composition and arrangement.
        </p>
        <p>
          Beyond her work as a piano teacher, Jennifer has served as a teaching
          assistant in university music theory courses, a choral performer, and
          an event organizer within the arts community. Her experience also
          extends internationally, having taught English as a second language in
          Taiwan, demonstrating her adaptability, strong communication skills,
          and explorative character.
        </p>
        <p>
          Jennifer’s teaching style is both structured and engaging, ensuring
          students progress while maintaining a love for music. With a keen eye
          for detail, a deep appreciation for musical expression, and a
          commitment to fostering creativity, she continues to inspire students
          of all ages to discover the joy of music.
        </p>
      </>
    ),
  },
];
const admins = [
  {
    image: sharynMooreAdmin,
    name: "Sharyn Moore",
    profession: "Artis Belmont Location frontdesk",
    recordingLink: "#",
    specialization: [
      { Icon: BsPersonWorkspace, amount: 3, name: "Management" },
    ],
    about: (
      <>
        <p>
          Sharyn is a native of the San Francisco Bay Area who has enjoyed
          singing in church choirs from the time of her childhood. She was
          fortunate to be introduced to English Handbell ringing as an
          elementary student in the public school system and these interests
          were further nourished by the opportunity to perform sacred music in
          the Church. As a college student, she was part of the professional
          local handbell choir, known as Bay Bells.
        </p>
        <p>
          This group boasts the reputation of being one of the nation’s
          longest-running community handbell ensembles. Much of Sharyn’s
          professional life has been spent working with traditional and
          non-traditional aged, adult student populations in higher education.
          When she’s not at Artis, you will be likely to find her gardening,
          advocating for students with disabilities, and teaching dance fitness
          classes.
        </p>
      </>
    ),
  },
  {
    image: ChandaTongo,
    name: "Chanda Tongo",
    profession: "Artis School Belmont Location Manager Assistant",
    recordingLink: "#",
    specialization: [
      { Icon: BsPersonWorkspace, amount: 1, name: "Management" },
    ],
    about: (
      <>
        <p>
          Chanda Tongo is from the San Francisco Bay Area, and Manila,
          Philippines. She is a sound designer, and an audio engineer who
          graduated with a Bachelor of Arts in Cinema, and a Minor in Music at
          San Francisco State University in 2021. She also got an Associate of
          Arts in Electronic Music Music Technology, and an Associate in Science
          in Film, TV, and Electronic Media for Transfer at College of San Mateo
          in 2019. Aside from her degrees, Chanda is formally trained in
          percussion primarily drums, and in basic piano. She is also a
          self-taught graphic designer who took Multimedia Art courses over the
          years; back in her time in the Philippines.
        </p>
        <p>
          Despite her background in the arts, she began her administrative
          duties as a secretary during her time as a member of the Epsilon
          Chapter of Delta Kappa Alpha. Chanda was a former customer service
          representative at Cosmic Soul Crystals, and a Information Technology
          Administration Volunteer at Boy & Girls Club of San Francisco. Her
          other former jobs involved food, such as being a baker’s assistant,
          and a barista for boba/milk tea. When Chanda is not working at Artis
          School of Music, she would use her time to be with her dog Zuko, hang
          out with loved ones, watch sports, listen to music, watch films and
          broadway shows, play video games, and take naps. Chanda has been
          working at Artis School of Music in the Admin team since 2023.
        </p>
        ,
      </>
    ),
  },

  {
    image: RachelL,
    name: "Rachel L.",
    profession: "Artis School of Music Belmont Front-desk",
    recordingLink: "#",
    specialization: [
      { Icon: BsPersonWorkspace, amount: 1, name: "Management" },
    ],
    about: (
      <p>
        While not musically talented, Rachel lends her unique talents to the
        Artis team by creating a clean, welcoming environment conducive to
        learning. A Bay Area native, Rachel was classically trained and received
        her Associate's in Art with emphasis in Art History from Cañada College
        in Redwood City. She has worked for various institutions including
        Stanford University, San Mateo Elections Office, and Artichoke Joe's
        Casino. When not working, Rachel enjoys partaking in fibercrafts,
        baking, or shopping on international websites.
      </p>
    ),
  },
  {
    image: IsabelDeCastro,
    name: "Isabel DeCastro",
    profession: "Artis Belmont Location Frontdesk",
    recordingLink: "#",
    specialization: [
      { Icon: BsPersonWorkspace, amount: 1, name: "Management" },
    ],
    about: (
      <p>
        Isabel has lived in the Bay Area her whole life, having just finished
        her bachelors in psychology at UC Santa Cruz. Though she did not study
        arts, they have always been a big part of her life, from doing Tech in
        High School for the Theater Department to enjoying musical theater. In
        her free time, she enjoys walking at Crystal Spring Reservoir, looking
        at the animals and nature, and listening to Taylor Swift.
      </p>
    ),
  },
  {
    image: ahmedElkamshoushyAdmin,
    name: "Ahmed Elkamshoushy",
    profession: "Web Developer",
    recordingLink: "#",
    specialization: [
      { Icon: BsPersonWorkspace, amount: 2, name: "Web Developer" },
    ],
    about: (
      <p>
        Hey, I'm Ahmed and I'm a web developer, I live in Egypt and I work with
        the technical team that is currently maintaining this website. I enjoy
        building websites and the best thing about my job is that I get to build
        something that can be used by a lot of people to make them have a better
        learning experience with the school. I enjoy playing guitar and video
        games in my free time. I will also respond to any technical issues and
        new features with the website.
      </p>
    ),
  },
  {
    image: NazarPankiv,
    name: "Nazar Pankiv",
    profession: (
      <>
        Meditation Teacher,
        <br />
        <br />
        Artis School Adviser
      </>
    ),
    recordingLink: "#",
    specialization: [
      { Icon: BsPersonWorkspace, amount: 7, name: "Artis Adviser" },
      { Icon: GiMeditation, amount: 7, name: "Meditation Teacher" },
    ],
    social: [
      {
        Icon: FaLinkedin,
        name: "LinkedIn profile",
        url: "https://www.linkedin.com/in/nazarasvitlo/",
      },
      {
        Icon: SiCrunchbase,
        name: "Crunchbase profile",
        url: "https://www.crunchbase.com/person/nazar-pankiv",
      },
    ],
    about: (
      <p>
        onelightsystem OLS Meditation Teacher. I teach a proper meditation,
        since 2017 Founded "a proper" Meditation method that allows me to
        continuously meditating and harvesting light energy with more
        realizations and understandings. Everyone need to know about it and
        practice it ! Do not wait everyday is an opportunity to receive direct
        Sun Light energy "safely" or "to remain in Light" to become a student
        For Artis School Of Music in 2025Q1 We give 50% discount before other
        perks during registration. Guest Map is Ready now ! And you can sign up
        for new student application.{" "}
        <a href="artismymusic.com">Artis School Meditation Teacher.</a>{" "}
      </p>
    ),
  },
];

const icons = teachers
  .reduce((accum, teacher) => {
    teacher.specialization.forEach((icon) => {
      let shouldNotBePushed = false;
      if (icon.notListedOnPI) {
        shouldNotBePushed = true;
      } else {
        accum.forEach((filterIcon) => {
          if (filterIcon.name === icon.name) {
            shouldNotBePushed = true;
          }
        });
      }

      if (!shouldNotBePushed) {
        accum.push(icon);
      }
    });
    return accum;
  }, [])
  .sort((a, b) => (a.name.length <= b.name.length ? -1 : 1));

const raisingTalents = teachers.filter((teacher) => {
  return (
    teacher.name === "James Woodward" || teacher.name === "Charlie Thollander"
  );
});

export { teachers, admins, raisingTalents, icons };
