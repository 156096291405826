import React, { useContext, useEffect, useState, useCallback, useMemo, memo } from "react";
import { Container, Col, Row } from "reactstrap";
import styled from "styled-components";
import { FirebaseContext } from "../../Firebase";
import { UserRole } from "../../../constants/UserRoleEnum";
import RouteContent from "../../common/RouteContent";
import { getDocs, query, where } from "firebase/firestore";
import {
  getFirebaseDoc,
  parseFirebaseDoc,
} from "../../../utils/getFirebaseDoc";
import {
  filterLessonsByLocation,
  filterStudentsByLessons,
  getUncompletePrivateStudents,
} from "../../../utils/helpers";
import LocationSelect from "../../common/LocationSelect";
import { PrimaryButton } from "src/utils/shared/styled";
import AddPrivateStudentModal from "../common/components/AddPrivateStudentModal";
import SearchBar from "../../common/Form/SearchBar/SearchBar";
import LearningStudentsSearch from "./LearningStudentsSearch";
import LearningStudentsSort from "./LearningStudentsSort";
import LearningStudentsView from "./LearningStudentsView";

// Styled Components
const SearchAndAddContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  padding: 1rem;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

// Context
export const LearningProfilesContext = React.createContext({});

// Main Component
const LearningProfiles = () => {
  const firebase = useContext(FirebaseContext);

  // State Declarations
  const [students, setStudents] = useState([]);
  const [parents, setParents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [instruments, setInstruments] = useState({});
  const [programs, setPrograms] = useState({});
  const [lessons, setLessons] = useState({});
  const [locations, setLocations] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isSorting, setIsSorting] = useState(false);
  const [showIncompleteStudents, setShowIncompleteStudents] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addStudentModalOpen, setAddStudentModalOpen] = useState(false);

  // Memoized Data
  const filteredLessons = useMemo(
    () => filterLessonsByLocation(lessons, selectedLocations),
    [lessons, selectedLocations]
  );

  const studentsFilteredByLessons = useMemo(
    () => filterStudentsByLessons(students, filteredLessons),
    [students, filteredLessons]
  );

  const incompleteStudents = useMemo(
    () => getUncompletePrivateStudents(students, lessons),
    [students, lessons]
  );

  const filteredStudents = useMemo(
    () => (showIncompleteStudents ? incompleteStudents : studentsFilteredByLessons),
    [showIncompleteStudents, incompleteStudents, studentsFilteredByLessons]
  );

  // Fetch Functions
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const [
        usersDocs,
        instrumentsDocs,
        programsDocs,
        teachersDocs,
        lessonsDocs,
        locationsDocs,
        schoolYearsDocs,
      ] = await Promise.all([
        getDocs(firebase.users()),
        getDocs(firebase.instruments()),
        getDocs(firebase.programs()),
        getDocs(query(firebase.users(), where("role", "==", UserRole.TEACHER))),
        getDocs(firebase.PrivateLessons()),
        getDocs(firebase.getLocations()),
        getDocs(firebase.getSchoolYears()),
      ]);

      // Process Users
      const users = usersDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setStudents(users.filter((user) => user.role === UserRole.STUDENT));
      setParents(users.filter((user) => user.role === UserRole.PARENT));
      setTeachers(getFirebaseDoc(teachersDocs));
      setInstruments(getFirebaseDoc(instrumentsDocs));
      setPrograms(getFirebaseDoc(programsDocs));
      setLessons(getFirebaseDoc(lessonsDocs));
      setLocations(
        locationsDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
      setSelectedLocations(
        locationsDocs.docs.map((doc) => ({ label: doc.data().name, value: doc.id }))
      );
      setSchoolYears(parseFirebaseDoc(schoolYearsDocs.docs));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [firebase]);

  // Initial Data Load
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Search Logic
  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredUsers([]);
      return;
    }
    const filtered = filteredStudents.filter((student) =>
      student.fullName?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchQuery, filteredStudents]);

  // Event Handlers
  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  const toggleShowIncomplete = () => setShowIncompleteStudents((prev) => !prev);
  const handleSort = () => setIsSorting(true);
  const onSelectLocation = (locationIds) => setSelectedLocations(locationIds);

  // Loading State
  if (loading) {
    return <div>Loading...</div>; // Consider a spinner component
  }

  return (
    <>
      <RouteContent title="Learning Profiles" />
      <SearchAndAddContainer>
        <Col xs={12} lg={6}>
          <SearchBar onChange={handleSearchChange} value={searchQuery} />
        </Col>
        <Col xs={12} lg={6}>
          <LocationSelect
            selectedLocations={selectedLocations}
            locations={locations}
            onSelectLocation={onSelectLocation}
          />
        </Col>
      </SearchAndAddContainer>

      <Row className="mt-3 mb-3">
        <Col className="text-end">
          <PrimaryButton width="auto" onClick={toggleShowIncomplete}>
            {showIncompleteStudents ? "Show Private Students" : "Show Incomplete Students"}
          </PrimaryButton>
        </Col>
      </Row>

      <Container fluid>
        <LearningProfilesContext.Provider
          value={{
            instruments,
            programs,
            teachers,
            students: filteredStudents,
            showIncompleteStudents,
            parents,
            lessons,
            locations,
            updateStudents: setStudents,
            updateLessons: setLessons,
            setLoading,
            loading,
            schoolYears,
          }}
        >
          {searchQuery ? (
            <LearningStudentsSearch searchQuery={searchQuery} filteredUsers={filteredUsers} />
          ) : isSorting ? (
            <LearningStudentsSort isSorting={isSorting} setIsSorting={setIsSorting} />
          ) : (
            <LearningStudentsView
              itemsPerPage={15}
              setIsSorting={setIsSorting}
              handleSort={handleSort}
            />
          )}
          <AddPrivateStudentModal
            isOpen={addStudentModalOpen}
            onAddStudentSuccess={(student) => {
              setStudents((prev) => [...prev, student]);
              setAddStudentModalOpen(false);
            }}
            onClose={() => setAddStudentModalOpen(false)}
            instruments={Object.values(instruments)}
            locations={locations}
            programs={Object.values(programs)}
            teachers={Object.values(teachers)}
          />
        </LearningProfilesContext.Provider>
      </Container>
    </>
  );
};

export default memo(LearningProfiles);