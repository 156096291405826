import React, { useCallback, useEffect, useMemo, useState } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { pageTabs } from "../constants";
import { toast } from "react-toastify";
import { isStudent } from "src/constants/UserRoleEnum";
import {
  isPendingMakeupRequest,
  isResolvedMakeupRequest,
} from "src/constants/makeupRequestEnum";
import { dateFilters } from "../../SuperAdmin/TeacherAbsence/constants";
import moment from "moment";

const customDateFilterInitialValues = {
  startDate: null,
  endDate: null,
};

const useMakeupRequests = () => {
  const {
    getTeachers,
    getTeacherAbsences,
    getInstruments,
    getLocations,
    getPrivateAndTrialStudents,
    getAdminCredentialsUsers,
    getUsers,
    getMakeupRequests,
    getPrivateLessonsByIds,
  } = useFirebaseFns();
  // the page current view
  const [currentView, setCurrentView] = useState(pageTabs.PENDING);

  const [initialData, setInitialData] = useState({
    users: [],
    makeupRequests: [],
    instruments: [],
    // startDateFilter: "",
    // endDateFilter: "",
    // locations: [],
    // combinedStudents: [],
    // adminCredentialsUsers: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [currentMakeupRequestId, setCurrentMakeupRequestId] = useState("");

  // search term from the search bar
  const [searchTerm, setSearchTerm] = useState("");

  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };
  // date filters
  const [dateFilter, setDateFilter] = useState(dateFilters.THIS_MONTH.value);
  const [customDateFilterValues, setCustomDateFilterValues] = useState(
    customDateFilterInitialValues
  );

  const handleDateFilterChange = (filterNewValue) => {
    setDateFilter(filterNewValue);
  };
  const handleCustomDateFilterChange = (name, value) => {
    if (name === "startDate") {
      setCustomDateFilterValues((oldVal) => ({
        ...oldVal,
        startDate: value,
        endDate: null,
      }));
    } else {
      setCustomDateFilterValues((oldVal) => ({
        ...oldVal,
        [name]: value,
      }));
    }
  };

  const getSearchTermStudentsIds = useCallback(() => {
    if (!searchTerm) {
      return initialData.users
        .filter(({ role }) => isStudent(role))
        .map(({ id }) => id);
    }

    const filteredStudents = initialData.users.filter(({ fullName }) =>
      fullName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const filteredStudentsIds = filteredStudents?.map(({ id }) => id);
    return filteredStudentsIds;
  }, [searchTerm, initialData]);

  const handleSearchTermChange = (value) => {
    setSearchTerm(value);
  };

  const isDateWithinDateFilter = useCallback(
    (date) => {
      switch (dateFilter) {
        case dateFilters.THIS_MONTH.value:
          return moment().startOf("month").isSame(date, "month");

        case dateFilters.NEXT_MONTH.value:
          const nextMonth = moment().add(1, "month").startOf("month");
          return moment(date).isSame(nextMonth, "month");

        case dateFilters.THIS_QUARTER.value:
          return (
            // if they are the same year, check if they are the same quarter
            moment().year() === moment(date).year() &&
            moment().quarter() === moment(date).quarter()
          );
        case dateFilters.NEXT_QUARTER.value:
          const nextQuarter = moment().add(1, "quarter");
          return (
            moment(date).year() === nextQuarter.year() &&
            moment(date).quarter() === nextQuarter.quarter()
          );

        case dateFilters.THIS_YEAR.value:
          return moment().startOf("year").isSame(date, "year");
        case dateFilters.CUSTOM.value:
          if (
            !customDateFilterValues.startDate ||
            !customDateFilterValues.endDate
          ) {
            return false;
          } else {
            return moment(date).isBetween(
              customDateFilterValues.startDate,
              customDateFilterValues.endDate,
              "date",
              "[]"
            );
          }
        default:
          return false;
      }
    },
    [dateFilter, customDateFilterValues]
  );

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [makeupRequests, users, instruments] = await Promise.all([
          getMakeupRequests(),
          getUsers(),
          getInstruments(),
        ]);
        const makeupRequestsPLIds = [
          ...new Set(makeupRequests.map(({ forLessonId }) => forLessonId)),
        ];
        const requestsPLs = makeupRequestsPLIds.length
          ? await getPrivateLessonsByIds(makeupRequestsPLIds)
          : [];

        const makeupRequestsWithData = makeupRequests.map((makeupRequest) => {
          const reqLesson = requestsPLs.find(
            ({ id }) => id === makeupRequest.forLessonId
          );
          const makeupRequestWithPl = {
            ...makeupRequest,
            privateLesson: reqLesson,
          };
          return makeupRequestWithPl;
        });

        setInitialData((oldVal) => ({
          ...oldVal,
          makeupRequests: makeupRequestsWithData,
          users,
          instruments,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err?.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [refresh]);

  const pendingMakeupRequests = useMemo(() => {
    const searchTermStudentsIds = getSearchTermStudentsIds();
    return initialData.makeupRequests.filter(
      ({ status, privateLesson, createdAt }) =>
        isPendingMakeupRequest(status) &&
        searchTermStudentsIds?.includes(privateLesson?.studentId) &&
        isDateWithinDateFilter(createdAt)
    );
  }, [initialData, getSearchTermStudentsIds, isDateWithinDateFilter]);

  const resolvedMakeupRequests = useMemo(() => {
    const searchTermStudentsIds = getSearchTermStudentsIds();
    return initialData.makeupRequests.filter(
      ({ status, privateLesson, createdAt }) =>
        isResolvedMakeupRequest(status) &&
        searchTermStudentsIds?.includes(privateLesson?.studentId) &&
        isDateWithinDateFilter(createdAt)
    );
  }, [initialData, getSearchTermStudentsIds, isDateWithinDateFilter]);

  // current absence obj
  const currentMakeupRequest = useMemo(() => {
    return initialData.makeupRequests.find(
      ({ id }) => id === currentMakeupRequestId
    );
  }, [currentMakeupRequestId, initialData]);

  return {
    currentView,
    setCurrentView,
    handleSearchTermChange,
    initialData,
    loadingInitialData,
    setCurrentMakeupRequestId,
    currentMakeupRequest,
    refreshData,
    pendingMakeupRequests,
    resolvedMakeupRequests,
    dateFilter,
    handleDateFilterChange,
    customDateFilterValues,
    handleCustomDateFilterChange,
  };
};

export default useMakeupRequests;
