import React, { useState, useEffect, useContext } from "react";
import RouteContent from "../../../common/RouteContent";
import {
  StudentsContext,
  StudentsSelectContext,
} from "../../../common/Library/common/libraryContext";
import "./style.css";
import { UserProfile } from "../../../../utils/styled";
import userImage from "../../../../images/userImage.png";
import { ScreenSize } from "../../../../constants/ScreenSizeEnum";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { MdDelete, MdEdit } from "react-icons/md";
import LearningProfileRecordModal from "./LearningProfileRecordModal";
import { FirebaseContext } from "../../../Firebase";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { downloadSingleItem, getStudents } from "../../../../utils/helpers";
import moment from "moment";
import { PROFILE_CATEGORIES } from "../constants/learning-profile";
import { toast } from "react-toastify";
import { AttachmentNameText } from "../../common/components/AddFeedItemModal/styled";
import { Empty } from "antd";
import {
  CloudSyncOutlined,
  CrownOutlined,
  DeploymentUnitOutlined,
  DiffOutlined,
  GithubOutlined,
  InstagramOutlined,
  PaperClipOutlined,
  RedditOutlined,
  StarOutlined,
} from "@ant-design/icons";
import ViewstateProfileModal from "./ViewItemProfileModal";
import { TextButton } from "../../../common/styled-components/teacherLibraryStyledComponents";
import DeleteModalRecord from "./DeleteModalRecord";
import ViewLearningProfileRecordModal from "./ViewLearningProfileRecordModal";
import { studentViews } from "../../../common/Library/common/libraryContext";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { UserRole } from "../../../../constants/UserRoleEnum";
import ProfileLibraryStudentsList from "./ProfileLibraryStudentsList";

function LearningProfileDetails({ UserStore }) {
  const [isLoading, setIsLoading] = useState(true);
  const [learningUser, setLearningProfiles] = useState(null);

  const [data, setData] = useState([]);
  const firebase = useContext(FirebaseContext);
  const [currentUser, setCurrentUser] = useState(null);
  const [editRecord, setEditRecord] = useState(null);
  const [viewFile, setViewFile] = useState(false);

  const groupedDateByYear = data.reduce((acc, curr) => {
    const year = moment(curr.createdAt).format("YYYY");
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push({ ...curr, year });
    return acc;
  }, {});
  const sortedData = Object.fromEntries(
    Object.entries(groupedDateByYear).sort(([yearA], [yearB]) =>
      yearB.localeCompare(yearB)
    )
  );
  const sortedDataArray = Object.values(sortedData);

  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.LG;
  const [showAddnewRecord, setShowAddnewRecord] = useState(false);
  const [showViewRecord, setShowViewRecord] = useState(false);
  const [activeFileRecord, setActiveFileRecord] = useState(null);
  const [showDeleteRecord, setShowDeleteRecord] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState(null);

  // Filter part
  const isTeacher = UserStore?.user?.role === UserRole.TEACHER;
  const [currentStudentsView, setCurrentStudentsView] = useState(
    studentViews.ALL
  );
  const [students, setStudents] = useState([]);
  const [teacherStudents, setTeacherStudents] = useState([]);

  const getStudentsList = async () => {
    const studentsArray = await getStudents(
      true,
      false,
      firebase,
      UserStore?.user.uid
    );
    setStudents(studentsArray);

    console.log("studentsArray", studentsArray);
  };
  const [selectedStudents, setSelectedStudents] = useState([]);

  const getTeacherStudents = async () => {
    const studentsArray = await getStudents(
      false,
      true,
      firebase,
      UserStore?.user.uid
    );
    setTeacherStudents(studentsArray);
  };

  const onStudentSelect = (id) => {
    if (Array.isArray(selectedStudents)) {
      const index = selectedStudents?.findIndex((ID) => id === ID);
      if (index === -1) {
        setSelectedStudents(id);
      } else {
        setSelectedStudents((prev) => prev.filter((ID) => id !== ID));
      }
    } else {
      setSelectedStudents(id);
    }
  };

  const onToggleStudentsClick = () => {
    if (selectedStudents.length === students.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(students.map(({ id }) => id));
    }
  };

  const onMultiStudentsSelect = (studentsIds) => {
    if (studentsIds.length === 0) {
      setSelectedStudents([]);
    } else {
      if (selectedStudents.length === 1) {
        setSelectedStudents(studentsIds[1]);
      }
    }
  };

  const onToggleTeachersClickClear = () => {
    setSelectedStudents([""]);
  };

  const studentsSelectContextValue = {
    selected: selectedStudents,
    select: onStudentSelect,
    toggle: onToggleStudentsClick,
    multiSelect: () => null,
    clear: onToggleTeachersClickClear,
  };

  const studentsContextValue = {
    students,
    teacherStudents,
    currentStudentsView,
  };

  useEffect(async () => {
    setIsLoading(true);
    getStudentsList();
    if (isTeacher) {
      getTeacherStudents();
    }
    setIsLoading(false);
  }, [isTeacher]);

  const findStudent = (id) => {
    const student = students.find((student) => student.id === id);
    setLearningProfiles(student);
  };

  useEffect(() => {
    if (selectedStudents.length !== 0) {
      getRecords(selectedStudents);
      findStudent(selectedStudents);
    }
  }, [selectedStudents]);

  /* end filter*/

  const getCurrentUser = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      setCurrentUser(user);
    } catch (error) {
      console.log(error, "getCurrentUser");
    }
  };

  const getRecords = async (selectedStudentsIndx) => {
    setIsLoading(true);
    try {
      const learningProfiles = collection(firebase.db, "LearningProfiles");
      const querySnapshot = await getDocs(learningProfiles);

      const profiles = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        ?.filter((profile) => selectedStudentsIndx.includes(profile?.userId));

      setData(profiles);
    } catch (error) {
      console.log(error, "getRecords");
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  const getCategoryName = (category) => {
    return PROFILE_CATEGORIES.find((cat) => cat?.value === category)?.label;
  };

  const deleteRecord = async (id) => {
    try {
      const docRef = doc(firebase.db, "LearningProfiles", id);
      await deleteDoc(docRef);
      toast.success("Record deleted successfully!");
      setShowDeleteRecord(false);
      await getRecords();
    } catch (error) {
      console.error("Error deleting record:", error);
      toast.error("Failed to delete the record.");
    }
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setShowAddnewRecord(true);
  };

  const onViewHandle = (record) => {
    setEditRecord(record);
    setShowViewRecord(true);
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case "teacher_assistance":
        return <RedditOutlined className="icon-md icon-color ms-2 me-2" />;
      case "performance":
        return <CrownOutlined className="icon-md icon-color ms-2 me-2" />;
      case "award":
        return <StarOutlined className="icon-md icon-color ms-2 me-2" />;
      case "sharing":
        return <CloudSyncOutlined className="icon-md icon-color ms-2 me-2" />;
      case "teacher_report":
        return <DiffOutlined className="icon-md icon-color ms-2 me-2" />;
      case "creative":
        return (
          <DeploymentUnitOutlined className="icon-md icon-color ms-2 me-2" />
        );
      case "leadership":
        return <GithubOutlined className="icon-md icon-color ms-2 me-2" />;
      case "social":
        return <InstagramOutlined className="icon-md icon-color ms-2 me-2" />;
      default:
        return <DiffOutlined className="icon-md icon-color ms-2 me-2" />;
    }
  };

  const modalTimer = () => {
    setShowViewRecord(false);
    setTimeout(() => {
      setShowViewRecord(true);
    }, 1000);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <RouteContent title="Learning Profile"></RouteContent>
      <StudentsSelectContext.Provider value={studentsSelectContextValue}>
        <StudentsContext.Provider value={studentsContextValue}>
          <div className="achievements-container">
            <div className="d-flex row">
              <div className="col-12 col-lg-3">
                <ProfileLibraryStudentsList fullWidth withToggle={false} />
              </div>
              <div className=" col-12 col-lg-9 profile-details-wrapper">
                <div className="d-flex justify-content-between align-items-center mb-5 ms-3">
                  <div>
                    <h3>Profile</h3>
                  </div>
                  <div className="d-flex align-items-center">
                    <UserProfile
                      width={"50px"}
                      height={"50px"}
                      className={"rounded-circle"}
                      src={learningUser?.imageUrl || userImage}
                    />
                    <div className="me-2 ms-2">
                      <h5>{learningUser?.fullName || ""}</h5>
                    </div>
                  </div>
                </div>
                <div className="timeline-container">
                  {data.map.length && (
                    <div className="timeline">
                      {data.map((month) => (
                        <div key={month} className="timeline-month">
                          <div className="timeline-dot"></div>
                          <div className="timeline-line"></div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="achievements-list">
                    {sortedDataArray.reverse().map((item) => (
                      <div key={item.id} className="achievement-card">
                        <div className={"d-flex justify-content-between"}>
                          <h3 className="month-title">{item[0]?.year}</h3>

                          <div
                            className="view-file-btn-main ms-2 mb-3"
                            onClick={() => setShowAddnewRecord(true)}
                          >
                            Add New Event
                          </div>
                        </div>
                        {item.map((record) => (
                          <div key={record.id} className="month-section">
                            <div
                              className="achievement-card"
                              onClick={() => onViewHandle(record)}
                            >
                              <div className="achievement-header">
                                <h4>{record.subject}</h4>
                                <span className={`achievement-status reviewed`}>
                                  {getCategoryIcon(record.category)}
                                  {getCategoryName(record.category)}
                                </span>
                              </div>
                              <div className="achievement-body d-flex justify-content-between flex-wrap">
                                <div className="d-flex flex-column">
                                  <div className="d-flex">
                                    <p>Teacher: {record.createdBy}</p>
                                  </div>
                                  <div className="d-flex">
                                    <p>Comment: {record.comment}</p>
                                  </div>
                                </div>

                                <div className="d-flex flex-column mt-3">
                                  {record?.attachments?.length
                                    ? record?.attachments.map((attachment) => (
                                        <div>
                                          <AttachmentNameText
                                            style={{
                                              cursor: "pointer",
                                              marginBottom: "5px",
                                            }}
                                            key={attachment.id}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setViewFile(true);
                                              setActiveFileRecord(attachment);
                                            }}
                                          >
                                            <PaperClipOutlined />
                                            {attachment?.name}
                                          </AttachmentNameText>
                                          <TextButton
                                            className={
                                              "p-1 text-purple ms-2 me-2"
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              downloadSingleItem(
                                                attachment.url,
                                                `${attachment.name}`
                                              );
                                            }}
                                          >
                                            <i className="fa fa-cloud-download-alt" />
                                          </TextButton>
                                        </div>
                                      ))
                                    : null}
                                </div>
                              </div>
                              <div className="achievement-actions">
                                <span className="achievement-date">
                                  {formatDate(record.createdAt)}
                                </span>

                                <div className="d-flex justify-content-center align-items-center">
                                  <button
                                    className="view-file-btn me-2"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleEdit(record);
                                    }}
                                  >
                                    <MdEdit color="#000" size={18} />
                                  </button>
                                  <button
                                    className="view-file-btn"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDeleteRecordId(record.id);
                                      setShowDeleteRecord(true);
                                    }}
                                  >
                                    <MdDelete color="#000" size={18} />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}

                    {!sortedDataArray?.length && <Empty />}
                  </div>
                </div>
              </div>
            </div>

            {showAddnewRecord && (
              <LearningProfileRecordModal
                learningUser={learningUser}
                currentUser={currentUser}
                initialData={editRecord}
                onSaved={() => getRecords()}
                toggleModal={() => setShowAddnewRecord(false)}
                isModalOpen
                closeModal={() => setShowAddnewRecord(false)}
              />
            )}

            {showViewRecord && (
              <ViewLearningProfileRecordModal
                learningUser={learningUser}
                currentUser={currentUser}
                initialData={editRecord}
                modalTimer={modalTimer}
                onSaved={() => getRecords()}
                toggleModal={() => setShowViewRecord(false)}
                isModalOpen
                closeModal={() => setShowViewRecord(false)}
              />
            )}

            {viewFile && (
              <ViewstateProfileModal
                state={activeFileRecord}
                closeModal={() => setViewFile(false)}
              />
            )}
            {showDeleteRecord && (
              <DeleteModalRecord
                isOpen={showDeleteRecord}
                onConfirm={() => deleteRecord(deleteRecordId)}
                closeModal={() => setShowDeleteRecord(false)}
                toggle={() => setShowDeleteRecord((prevState) => !prevState)}
              />
            )}
          </div>
        </StudentsContext.Provider>
      </StudentsSelectContext.Provider>
    </div>
  );
}

export default compose(inject("UserStore"), observer)(LearningProfileDetails);
